<template>
  <section class="section-action default-grid row-gap">
    <h2 class="section-action__title">
      <slot name="title" />
    </h2>
    <div class="section-action__action">
      <slot name="action" />
    </div>
    <div class="section-action__body">
      <slot />
    </div>
  </section>
</template>

<script setup>

</script>

<style scoped lang="scss">
.section-action {
  &__title {
    grid-column: span 7;
    font-size: 36px;
    font-weight: 900;
    line-height: 48px;
    text-transform: uppercase;
    color: var(--color-elements-primary);

    @media (max-width: 920px) {
      font-size: 28px;
      line-height: 34px;
      letter-spacing: 1px;
      grid-column: span 12;
    }
  }
  &__action {
    grid-column: 9 / 13;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 920px) {
      grid-column: span 12;
      justify-content: stretch;
      order: 2;
    }

    > :slotted(*) {
      padding: 30px 26px;
      @media (max-width: 920px) {
        padding: 30px 10px;
        width: 100%;
        font-size: 20px;
        font-weight: 800;
        line-height: 24px;
      }
    }
  }
  &__body {
    grid-column: span 12;
  }
}
</style>
